export default {
    title: 'NOSLO',
    location: '/process/noslo',
    tabs: [
        {
            name: 'By Country',
            link: `/process/noslo`
        },
        {
            name: 'Trend',
            link: `/process/noslo/:market`
        }
    ]
}